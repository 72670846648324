<template>
  <div>
    <van-cell-group  inset style="margin-top: 10px;padding: 10px;">
  <van-cell style="background-color: #DDEFFF" title="户主/户号" :value="`${username}/${item.consumer_no}`" />
  <van-cell title="手机号" :value="phone" />
  <van-cell title="地址/水牌号" :value="`${item.region_name}/${item.water_number}`" />
  <van-cell title="详细地址" :value="this.address" />
  <van-cell title="水表位置" :value="this.place" />
  <van-cell title="余额" :value="this.money" />
  <van-cell title="上次抄表吨" :value="this.last_ons" />

  <van-cell title="上次抄表" :value="item.lasttime" />
  <van-cell title="备注" :value="item.memo" />

  <div style="margin:0px 16px 0px 16px;">
    <van-row>
  <!-- <van-col v-if="this.itemphone==''"  span="12"><van-button  type="info" size="small" block  @click="$router.push(`/records/${item.id}`)" >去抄表</van-button></van-col>
  <van-col v-if="this.itemphone!==''" span="24"><van-button  type="info" size="small" block  @click="$router.push(`/records/${item.id}`)" >去抄表</van-button></van-col>
  <van-col v-if="this.itemphone==''" span="12">  <van-button  type="primary" size="small" block  @click="sss" >修改资料</van-button></van-col> -->

  <van-col v-if="this.money!==''&&this.last_ons!==''"  span="12"><van-button  type="info" size="small" block  @click="$router.push(`/records/${item.id}`)" >去抄表</van-button></van-col>
  <van-col  span="12"> <van-button  type="primary" size="small" block  @click="sss" >修改资料</van-button></van-col>

</van-row>
<van-dialog @confirm="abc"  v-model="show" :title="this.item.username" show-cancel-button>
  <van-cell-group>
  <van-field v-model="username" label="用户名" placeholder="请输入用户名" />
  <van-field v-model="consumer_no" label="户号" placeholder="请输入户号" />
  <van-field v-model="phone" label="手机号" placeholder="请输入用户手机号" />
  <van-field v-model="water_number" label="水牌号" placeholder="请输入水牌号" />
  <van-field v-model="address" label="详细地址" placeholder="请输入详细地址" />
  <van-field v-model="place" label="水表位置" placeholder="请输入水表位置" />
  <van-field v-model="card" label="身份证" placeholder="请输入身份证号" />
  <van-field v-model="last_ons" label="上次抄表吨数" placeholder="请输入上次抄表吨数" />
  <van-field v-model="money" label="上次抄表金额" placeholder="请输入上次抄表金额" />
  <van-field name="switch" label="上次抄表时间为现在">
  <template #input>
    <van-switch v-model="switchChecked" size="20" />
  </template>
</van-field>
  <van-field v-model="memo" label="备注" placeholder="请输入备注" />
</van-cell-group>
</van-dialog>

</div>
</van-cell-group>

  </div>

</template>

<script>
import { consumerphone } from '@/api/consumer'
export default {
  name: 'ConsumerItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      consumer_id: this.item.id,
      show: false,
      phone: this.item.phone,
      address: this.item.address,
      consumer_no: this.item.consumer_no,
      username: this.item.username,
      place: this.item.place,
      water_number: this.item.water_number,
      card: this.item.card,
      money: this.item.money != null ? this.item.money : '',
      last_ons: this.item.last_ons != null ? this.item.last_ons : '',
      memo: this.item.memo,
      switchChecked: false

    }
  },
  methods: {
    // dialog () {
    //   this.$dialog.confirm({
    //     title: '用主',
    //     message: this.username
    //   })
    //     .then(() => {
    //       alert(this.consumer_id)
    //     })
    //     .catch(() => {
    //       // on cancel
    //     })
    // },

    sss () {
      this.show = true
    },
    async abc () {
      const res = await consumerphone({
        consumer_id: this.item.id,
        phone: this.phone,
        address: this.address,
        place: this.place,
        consumer_no: this.consumer_no,
        username: this.username,
        water_number: this.water_number,
        card: this.card,
        money: this.money,
        last_ons: this.last_ons,
        memo: this.memo,
        switchChecked: this.switchChecked
      })
      if (res.code === 1) {
        // this.$router.go(0)
      //  this.money =
        // this.item.money = this.money
        // this.item.last_ons = this.last_ons
        this.$toast.success('操作成功')
      } else {
        this.$toast.fail(res.msg)
      }
    }
  }
}
</script>

<style>
.van-cell__value{
flex: 2;
}

</style>
