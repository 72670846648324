<template>
  <div>
    <van-cell-group inset>
      <van-cell>
        <template #label
          ><div style=" font-weight: bold">
            {{item.paytype}}   上次结余:{{ item.before_money }}元 本次结余:{{ item.after_money }}元
          </div></template
        >
        <template #title
          ><div style="color: #1989fa; font-weight: bold; font-size: large">
            {{ item.paytime }}
            <span style="color: #969799; font-size: small"
              >缴纳：：{{item.amount}}元</span
            >
            <!-- <span style="color: #969799; font-size: small"
              > 实缴：{{item.amount}}元</span
            > -->
          </div></template
        >
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: 'PayLogItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>
</style>
