import axios from 'axios'
import { Toast } from 'vant'
import { delOpenid, delToken, delUrl, getToken, setUrl } from './storage'
import router from '@/router'
// import router from '@/router'

const instance = axios.create({
  baseURL: 'https://water.68huiyuan.top',
  timeout: 5000
  // headers: { 'X-Custom-Header': 'foobar' }
})

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  const token = getToken()
  if (token) {
    config.headers.token = `${token}`
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response.data
}, function (error) {
  // 对响应错误做点什么
  // return Promise.reject(error)
  // console.log(error.response)
  // console.log(this.$route.path)

  if (error.response) {
    if (error.response.status === 401) {
    //  alert(window.location.pathname)
      //  alert(window.navigator.userAgent.match(/MicroMessenger/i))
      // alert(window.navigator.userAgent.match(/MicroMessenger/i) === 'micromessenger')

      const ua = window.navigator.userAgent.toLowerCase()
      console.log(ua)
      if (ua.match(/MicroMessenger/i)[0] === 'micromessenger') {
        delToken()
        delUrl()
        delOpenid()
        setUrl(window.location.pathname)
        window.location.href = 'https://waterclient.68huiyuan.top/shouquan'
      } else {
        delToken()
        router.push('/login')
      }
    } else {
      Toast.fail(error.response.data.message)
    }
  }
})

export default instance
