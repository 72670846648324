import Vue from 'vue'
import VueRouter from 'vue-router'
const Register = () => import('@/views/Register')
const Login = () => import('@/views/Login')
const Clock = () => import('@/views/Clock')
const Layout = () => import('@/views/Layout')
const Index = () => import('@/views/Index')
const Exam = () => import('@/views/Exam')
const User = () => import('@/views/User')//
const Userinfo = () => import('@/views/Userinfo')//
const Bindstaff = () => import('@/views/Bindstaff')//
const Consumer = () => import('@/views/Consumer')
const Records = () => import('@/views/Records')
const Recordslist = () => import('@/views/Recordslist')
const Recordsinfo = () => import('@/views/Recordsinfo')
const ConfirmOrder = () => import('@/views/Confirmorder')
const Order = () => import('@/views/Order')
const Orderinfo = () => import('@/views/Orderinfo')
const Orderlist = () => import('@/views/Orderlist')
const Alert = () => import('@/views/Alert')
const Collect = () => import('@/views/Collect')
const Repair = () => import('@/views/Repair')
const Corderlist = () => import('@/views/Corderlist')
// const Paper = () => import('@/views/Paper')
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/index' },
  { path: '/register', component: Register },
  { path: '/login', component: Login },
  { path: '/userinfo', component: Userinfo },
  { path: '/bindstaff', component: Bindstaff },
  { path: '/consumer', component: Consumer },
  { path: '/records/:id', component: Records },
  { path: '/recordslist', component: Recordslist },
  { path: '/corder', component: Corderlist },

  { path: '/confirmorder/from/:from/records_id/:records_id/order_id/:order_id', component: ConfirmOrder },
  // { path: '/detail/:id', component: Detail },
  { path: '/recordsinfo/:id', component: Recordsinfo },
  { path: '/alert', component: Alert },
  { path: '/order', component: Order },
  { path: '/orderlist', component: Orderlist },
  { path: '/orderinfo/records_id/:records_id/order_id/:order_id', component: Orderinfo },
  { path: '/collect', component: Collect },
  { path: '/repair', component: Repair },
  { path: '/paper', component: function () { return import('@/views/Paper') } },
  { path: '/clockinfo', component: function () { return import('@/views/Clockinfo') } },

  { path: '/waterrate', component: function () { return import('@/views/Waterrate') } },
  // { path: '/test', component: function () { return import('@/public/index.html') } },
  {
    path: '/',
    component: Layout,
    children: [
      { path: '/index', component: Index },
      { path: '/clock', component: Clock },
      { path: '/exam/:id', component: Exam },
      { path: '/user', component: User }
    ]
  },
  { path: '/cons', redirect: '/cons/index' },
  {
    path: '/cons',
    component: function () { return import('@/views/cons/Layout') },
    children: [
      { path: '/cons/index', component: function () { return import('@/views/cons/Index') } },
      { path: '/cons/work', component: function () { return import('@/views/cons/Work') } }
    ]
  },
  { path: '/shouquan', component: function () { return import('@/views/shouquan') }, props: route => ({ code: route.query.code }) },
  { path: '/cons/bindconsumer', component: function () { return import('@/views/cons/BindConsumer') } },
  { path: '/cons/paylog', component: function () { return import('@/views/cons/PayLog') } },
  { path: '/cons/paynewlog', component: function () { return import('@/views/cons/PayNewLog') } },
  { path: '/cons/billlog', component: function () { return import('@/views/cons/BillLog') } },
  { path: '/cons/payconfirm', component: function () { return import('@/views/cons/Payconfirm') } },
  { path: '/cons/paysuccess', component: function () { return import('@/views/cons/Paysuccess') } },
  { path: '/cons/consumerrepair', component: function () { return import('@/views/cons/Consumerrepair') } }

]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  // if (to.meta.title) {
  document.title = '葛天水务'
  // }
  next()
})

export default router
